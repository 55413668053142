<template>
	<div class="home-container">
		<img src="~@/assets/img/bg.jpg" />
	</div>
</template>

<script>

export default {
  name: 'home',
  components: {

  },

  data () {
    return {
	  
    }
  },


  mounted () {
	
  },
  activated () {
  },

  methods: {
    
  }
}	
</script>

<style lang="scss">
@import url('~@/assets/css/font.css');
.home-container {
	// height: 5717px;
	// background: url('~@/assets/img/bg.jpg');
	// background-repeat: no-repeat; 
	// background-position: center; 
	// background-size: cover;
  text-align: center;
  line-height: 0;
  img {
    max-width: 100%;
  }
}
</style>
