import Vue from 'vue'
import Vuex from 'vuex'

Vue.use(Vuex)

export default new Vuex.Store({
  state: {
    userData: {
      spreadChannelId: '',
      spreadUserId: ''
    }
  },
  mutations: {
    USER_DATA (state, value) {
      state.userData = value
    }
  },
  actions: {
    USER_DATA (ctx, value) {
      ctx.commit(USER_DATA, value)
    }
  },
  modules: {
  }
})
